<template>
  <div class="box">
    <h3 class="title">
      <img src="../../../assets/images/icon_wwfwl.png" alt="">
      <p>差异详情</p>
      <span>({{ detailData.stateText }})</span>
    </h3>
    <!--信息-->
    <div>
      <ProcessHeader :isMatrix="isMatrix" :detail-data="detailData" :info-data="infoData"
        @matrixSwitch="(v) => matrixSwitch(v)" />
    </div>
    <div v-if="type !== 'store'">
      <!-- 审核差异 -->
      <!-- 如果不是初审，而且没有任何判责值，证明没有初审过，不显示任何初审信息 -->
      <div class="examine_box">
        <div class="examine"
          v-if="(detailData.state !== '00' && responsibilityData.some(v => v.value === submitData.undertakeSubject)) || detailData.state === '00'">
          <div class="examine_left examine-inner">
            <div class="info_title">
              审核差异
            </div>
            <div class="examine_left_inner examine_main">
              <!-- 审批判责 -->
              <div class="responsibility">
                <p class="responsibility_title">审批判责：</p>
                <div class="responsibility_select">
                  <div v-for="item in responsibilityData" :key="item.value" @click="responsibilityChange(item)">
                    <img v-if="submitData.undertakeSubject === item.value" src="../images/icon_select.png" alt="">
                    <img v-else src="../images/icon_no_select.png" alt="">
                    <span>{{ item.label }}</span>
                  </div>
                </div>
              </div>
              <!-- 共同承担依据上传tab -->
              <div class="responsibility_category" v-if="submitData.undertakeSubject === 'T'">
                <p :class="[tabSquare === '01' ? 'responsibility_category_active' : '']" @click="tabSquare = '01'">门店依据
                </p>
                <p :class="[tabSquare === '02' ? 'responsibility_category_active' : '']" @click="tabSquare = '02'">
                  回收商判责</p>
              </div>
              <!-- 依据上传 -->
              <div class="judgements_box"
                v-if="submitData.undertakeSubject === 'T' && tabSquare || submitData.undertakeSubject === 'C' || submitData.undertakeSubject === 'M'">
                <JudgementsList :is-reset="isResponsibilityReset" :source-data="submitData.judgements"
                  @dataChange="v => judgementsChange(v)" :assignmentData="assignmentData" :state="detailData.state" />
              </div>
              <div class="money_original" v-if="submitData.undertakeSubject !== 'M' && submitData.undertakeSubject">
                <label class="responsibility_title" for="arguePriceAudit">初审追差金额：</label>
                <el-input style="width: 178px;margin-right: 12px;" name="arguePriceAudit"
                  :value="submitData.arguePriceAudit" :disabled="detailData.state !== '00'"
                  @input="v => submitData.arguePriceAudit = !getFloat2Value(v) ? '' : getFloat2Value(v)"
                  placeholder="请输入初审追差金额" minlength="1" />
                <span>元</span>
              </div>
              <div class="inner_line" v-if="detailData.state !== '00'"
                style="margin-top: 20px;justify-content: space-between">
                <div class="inner_line">
                  <p class="inner_label">初审人：</p>
                  <p>{{ detailData.auditFirstInfo }}</p>
                </div>
                <div class="inner_line">
                  <p class="inner_label">初审时间：</p>
                  <p>{{ detailData.auditFirstTime }}</p>
                </div>

              </div>
            </div>
          </div>
          <div class="examine_right examine-inner">
            <div class="info_title">
              差异处理建议
            </div>
            <div class="examine_right_inner examine_main">
              <div class="examine_right_input">
                <div class="examine_right_title">门店验机建议：</div>
                <textarea placeholder="请输入门店验机建议，50字以内" maxlength="50"
                  :class="['examine_right_title', detailData.state === '00' ? '' : 'disable_result']"
                  :disabled="detailData.state !== '00'" v-model="submitData.companySuggest"></textarea>
              </div>
              <div class="examine_right_input">
                <div class="examine_right_title">回收商报价建议：</div>
                <textarea placeholder="请输入回收商报价建议，50字以内" maxlength="50"
                  :class="['examine_right_title', detailData.state === '00' ? '' : 'disable_result']"
                  :disabled="detailData.state !== '00'" v-model="submitData.merchantSuggest"></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- 新增 是否允许门店提前查看配置 -->
        <div class="permit_see" v-if="firstButtonShow">
          <span class="permit_tex">是否允许门店端查看</span>
          <el-switch v-model="permitSeeOpen" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
            @change="(e) => permitSee(e, row)">
          </el-switch>
          <span style="color:#ff8080 ;margin-left: 16px;">说明：开启后，如审核通过为门店责任，创建人子账号可在【我的-差异订单-待审核】查看该订单</span>
        </div>
      </div>
      <!-- 提交初审 -->
      <div class="submit_box" v-if="firstButtonShow">
        <!-- submit -->
        <el-button :loading="firstLoading" @click="firstSubmit" type="primary">提交初审结果</el-button>
        <!-- 新增 暂存 按钮 -->
        <el-button style="margin-left: 30px;width: 90px;" :loading="temporaryLoading" @click="temporaryStore"
          type="warning">暂存</el-button>
      </div>
      <!-- 暂存原因弹框 -->
      <el-dialog title='操作提示' :visible.sync="temporaryShow" :close-on-click-modal="false" width="680px"
        @closed="temporaryClose">
        <div style="padding: 0 15px;box-sizing: border-box;">
          <div class="temporary">选择暂存原因:</div>
          <el-radio-group style="display: block !important;margin-bottom: 15px;" v-model="chooseTemReason"
            v-for="item in temporaReasonList" :key="item.key">
            <el-radio :label="item.name">{{ item.name }}</el-radio>
          </el-radio-group>
          <!-- 其他原因 -->
          <el-input v-if="chooseTemReason == '其他原因'" type="textarea" :rows="5" placeholder="请输入暂存原因，最多100字"
            v-model="otherReason" maxlength="200"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click.native="temporaryShow = false">取消</el-button>
          <el-button type="primary" @click.native="temporarySubmit">确定</el-button>
        </span>
      </el-dialog>
      <!-- 切换责任提示 -->
      <DialogAction v-bind="switchResponsibility" @close="switchResponsibility.show = false"
        @config="switchResponsibilityConfig" />
      <!-- 复核打款 -->
      <div class="check_money_box" v-if="detailData.state === '01'">
        <div class="check_money_title">
          <p class="info_title">复核打款</p>
          <p v-if="moneyWay === '01'">（本单线上结算差异款）</p>
          <p v-else  style="color:#4C84FF">（本单线下结算差异款）</p>
        </div>
        <div class="check_money_main">
          <!-- 复核打款 -->
          <div class="money_original" style="margin-top: 0;">
            <label class="responsibility_title" for="arguePriceAudit"><span
                style="color: #ff8080;">*</span>复核打款金额：</label>
            <el-input style="width: 178px;margin-right: 12px;" name="arguePriceAudit" :value="submitData.rePayPrice"
              type="number"
              @input="v => submitData.rePayPrice = getFloat2Value(v, Number(this.detailData.arguePrice)) <= 0 ? 0 : getFloat2Value(v, Number(this.detailData.arguePrice))"
              placeholder="请输入审批追差金额" minlength="1" />
            <span>元</span>
            <!--          <span class="check_money_money">初审通过金额：<span>300元</span></span>-->
          </div>
          <!-- 打款方式 -->
          <div style="margin-top: 24px;">
            <label class="responsibility_title" for="arguePriceAudit" style="margin-right: 15px;"><span
                style="color: #ff8080;">*</span>打款方式：</label>
            <el-radio-group v-model="moneyWay">
              <el-radio :label="'01'" :disabled="(submitData.rePayPrice == 0 || !(this.infoData.outType === 'fx' || this.infoData.outType === '--')) ? true : false">线上打款</el-radio>
              <el-radio :disabled="(submitData.rePayPrice == 0) ? true : false"
                :label="'00'">线下打款</el-radio>
            </el-radio-group>
          </div>
          <!-- 线上打款 -->
          <!-- 使用差异基金 -->
          <div v-if="moneyWay === '01'">
            <div class="switch_money_origin money_original" style="display: flex;align-items: center">
              <el-switch style="margin-right: 12px" v-model="isDiffFundPrice">
              </el-switch>
              <label class="responsibility_title" for="diffFundPrice">
                使用差异基金
                <span v-if="detailData.outType === 'fx'" style="color: #999">（矩阵原订单商户差异基金：{{
                  detailData.oriDiffFundPriceAccount || 0
                }}元）</span>
                <span v-else style="color: #999">（当前商户差异基金：{{
                  detailData.diffFundPriceAccount
                }}元）</span>

              </label>
              <!--          -->
              <!-- 商户关闭或者当前是fx渠道或者普通订单时，最大值又是0，限制不可输入或者限制最大值 -->
              <!-- 差异基金所有情况都要限制最大值 -->
              <!-- 原： @input="v => submitData.diffFundPrice = getFloat2Value(v, detailData.outType === 'fx' ? detailData.oriDiffFundPriceAccount : detailData.diffFundPriceAccount) <= 0 ? 0 : getFloat2Value(v, detailData.outType === 'fx' ? detailData.oriDiffFundPriceAccount : detailData.diffFundPriceAccount)" -->
              <el-input style="width: 200px;margin-right: 12px;" name="diffFundPrice" :disabled="!isDiffFundPrice"
                v-model="submitData.diffFundPrice" type="number" @input="inputDiffFundPrice" placeholder="请输入使用基金补差金额"
                minlength="1" />
              <!-- 此处不限制最大值 -->
              <!--            <el-input-->
              <!--                v-else-->
              <!--                style="width: 200px;margin-right: 12px;"-->
              <!--                name="diffFundPrice"-->
              <!--                :disabled="!isDiffFundPrice"-->
              <!--                v-model="submitData.diffFundPrice"-->
              <!--                type="number"-->
              <!--                @input="v=>submitData.diffFundPrice = getFloat2Value(v)"-->
              <!--                placeholder="请输入使用基金补差金额"-->
              <!--                minlength="1"/>-->
              <span>元</span>
              <!-- 新增：统计 -->
              <div class="statatics">
                平台差异基金：<span style="color:red">{{ allDiffFund }}</span>元；该回收商净入账差异基金：<span style="color:red">{{
                  merchantDiffFund }}</span>元 <el-button style="margin-left: 15px;" type="warning" size="mini"
                  @click="refreshData">刷新</el-button>
              </div>
            </div>
            <!-- 商户余额扣除 -->
            <div class="switch_money_origin money_original" style="display: flex;align-items: center">
              <el-switch style="margin-right: 12px" v-model="isDeBalanceVoucher">
              </el-switch>
              <label class="responsibility_title" for="deStoreBalance">
                扣除商户余额
                <i style="color: #FD677D;font-style: normal">(谨慎操作)</i>:
                <span v-if="detailData.outType === 'fx'" style="color: #999">（矩阵原订单商户余额：{{
                  detailData.oriBalance || '--'
                }}元）</span>
                <span v-else style="color: #999">
                  （当前商户账户余额：{{ detailData.balance }}元
                  <span v-if="detailData.outType !== 'fx' && detailData.outType !== '--'">，本单订单利润：{{
                    accAdd(detailData.rebateAfter, detailData.rebateDepreciate)
                  }}元</span>）
                </span>
              </label>
              <el-input v-if="detailData.outType === 'fx' || detailData.outType === '--'" disabled
                style="width: 200px;margin-right: 12px;" name="deStoreBalance" v-model="submitData.deStoreBalance"
                type="number" placeholder="请输入扣除商户余额" minlength="1" />
              <el-input v-else disabled style="width: 200px;margin-right: 12px;" name="deStoreBalance"
                v-model="submitData.deStoreBalance" type="number" placeholder="请输入扣除商户余额" minlength="1" />
              <span>元</span>
              <span v-if="isDeBalanceVoucher" class="inner_href_text" style="margin-left: 17px;"
                @click="deBalanceVoucherProps.show = true; deBalanceSumit.isEdit = true;">修改扣款余额</span>
            </div>
          </div>
          <!-- 线下打款 -->
          <div v-if="moneyWay === '00'" style="margin-top: 30px;">
            <label class="responsibility_title" for="arguePriceAudit"><span
                style="color: #ff8080;">*</span>线下打款金额：</label>
            <el-input style="width: 178px;margin-right: 12px;" name="arguePriceAudit" :value="submitData.rePayPrice"
              type="number" disabled>
            </el-input><span>元</span>
            <div style="color: #ff8080;margin-top: 10px;">线下打款金额默认复核打款金额</div>
          </div>
        </div>
      </div>
      <!-- 新加：差异订单备注 -->
      <div class="check_money_box" v-if="detailData.state === '01'">
        <div class="check_money_title">
          <p class="info_title">差异订单备注</p>
        </div>
        <el-input maxlength="300" type="textarea" :rows="4" placeholder="请输入备注内容,最多300字" v-model="RemarkslogTxt">
        </el-input>
      </div>
      <!-- 复核按钮(线上打款) -->
      <div class="check_money_btn" v-if="checkButtonShow && moneyWay !== '00'">
        <div class="direct_btn click_hover" v-if="isDeBalanceVoucher" :class="{ 'loading': checkLoading1 }"
          @click="checkSubmit('00')" :style="{ 'pointer-events': checkLoading1 ? 'none' : 'auto' }">
          <span v-if="checkLoading1" class="el-icon-loading"></span> 提交直接打款
        </div>
        <div class="submit_btn click_hover" v-if="isDeBalanceVoucher" :class="{ 'loading': checkLoading2 }"
          @click="checkSubmit('01')" :style="{ 'pointer-events': checkLoading2 ? 'none' : 'auto' }">
          <span v-if="checkLoading2" class="el-icon-loading"></span> 确认提交
        </div>
        <div class="click_hover submit_btn no_submit_btn" v-if="!isDeBalanceVoucher" :class="{ 'loading': checkLoading2 }"
          :style="{ 'pointer-events': checkLoading2 ? 'none' : 'auto' }" @click="checkSubmit('00')">
          <span v-if="checkLoading2" class="el-icon-loading"></span> 确认提交
        </div>
      </div>
      <!-- 复核按钮(线下打款)--新加 -->
      <div v-if="moneyWay === '00'" class="click_hover offline_submit" :style="{ 'pointer-events': checkLoading2 ? 'none' : 'auto' }" @click="checkSubmit('08')">
        确认提交
      </div>
      <!-- 几项结果 -->
      <div class="result_data" v-if="detailData.state !== '00' && detailData.state !== '01'">
        <!-- 复核打款 -->
        <div class="check_result" v-if="detailData.rePayPrice !== '--'">
          <div class="info_title" style="margin-bottom: 20px;">复核打款</div>
          <div class="result_data_main">
            <div class="inner_line" style="justify-content: space-between">
              <div><span class="inner_label">复核打款金额：</span><span>{{ detailData.rePayPrice }}元</span></div>
              <span class="inner_title_red">(差异基金豁免{{
                detailData.diffFundPrice
              }}元，门店商打款{{ detailData.deStoreBalance }}元)</span>
            </div>
            <div class="inner_line" style="justify-content: space-between;margin-bottom: 0;">
              <div class="inner_line" style="margin-bottom: 0;">
                <div class="inner_label">复核人：</div>
                <div>{{ detailData.auditReAdminInfo }}</div>
              </div>
              <div class="inner_line" style="margin-bottom: 0;">
                <div class="inner_label">复核时间：</div>
                <div>{{ detailData.auditReAdminTime }}</div>
              </div>
              <div class="inner_href_text click_hover" v-if="detailData.deBalanceVoucher !== '--'" @click="viewVoucher">
                查看沟通凭证
              </div>
            </div>
            <!-- 该差异订单为与矩阵回收商成交的矩阵映射订单显示 -->
            <div class="inner_line" style="margin-top: 10px;" v-if="detailData.outType==='fx'">
              <span>{{ detailData.oriCompanyName || '--'}}:</span>
              <span class="inner_title_red">(差异基金豁免{{
                detailData.diffFundPriceOri
              }}元，门店商打款{{ detailData.deStoreBalanceOri }}元)</span>
            </div>
          </div>
        </div>
        <!-- 门店待打款 -->
        <div class="store_money" v-if="detailData.state === '02'">
          <div class="inner_line">
            <div class="info_title">门店待打款</div>
            <p class="inner_title_red" v-if="Number(this.detailData.unPayCountdownSecond) > 0" style="margin-left: 13px;">
              {{ residueTime }}后系统自动打款</p>
            <p class="inner_title_red" v-else style="margin-left: 13px;">即将打款</p>
          </div>
          <div class="result_data_main">
            <div class="inner_line">
              <div class="inner_label">待打款金额：</div>
              <span>{{ detailData.deStoreBalance }}元</span>
            </div>
          </div>
        </div>
        <!-- 门店已打款 -->
        <div class="store_money" v-if="detailData.state === '04'">
          <div class="inner_line">
            <div class="info_title">门店打款</div>
          </div>
          <div class="result_data_main">
            <div class="inner_line">
              <div class="inner_label">已打款金额：</div>
              <span>{{ detailData.deStoreBalance }}元</span><span v-if="detailData.moneyWay" :style="detailData.moneyWay==='01' ? 'color: green;' : 'color: red;'">({{detailData.moneyWay==='00'?'线下打款':'线上打款'  }})</span>
            </div>
            <div class="inner_line" style="justify-content: space-between;margin-bottom: 0;">
              <div class="inner_line" style="margin-bottom: 0;">
                <div class="inner_label">确认打款人：</div>
                <div v-if="detailData.payWay === '00'">{{ detailData.payStaffInfo || '--' }}</div>
                <div v-else-if="detailData.payWay === '01'">{{
                  detailData.auditReAdminInfo || '--'
                }}（平台直接操作）
                </div>
                <div v-else-if="detailData.payWay === '02'">超时系统自动打款</div>
                <div v-else>--</div>
              </div>
              <div class="inner_line" style="margin-bottom: 0;">
                <div class="inner_label">确认打款时间：</div>
                <div v-if="detailData.payWay === '01'">{{ detailData.auditReAdminTime }}</div>
                <div v-else>{{ detailData.payTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 追差关闭 -->
        <div class="close_result" v-if="detailData.state === '10' || detailData.state === '20'">
          <div class="info_title inner_line">追差关闭</div>
          <div class="result_data_main">
            <div class="inner_line" v-if="detailData.state === '20'">
              <p>回收商撤回追差，追差已关闭</p>
            </div>
            <div class="inner_line" style="justify-content: space-between" v-else>
              <div><span class="inner_label">关闭原因：</span><span>{{ getRejectMark(detailData.rejectMark) }}</span>
              </div>
              <div class="inner_href_text click_hover" @click="viewJudgements"
                v-if="(Array.isArray(detailData.judgementsReject) && detailData.judgementsReject.length) || detailData.rejectMark === '00'">
                查看判责依据
              </div>
            </div>
            <div class="inner_line" style="justify-content: space-between;margin-bottom: 0;">
              <div class="inner_line" style="margin-bottom: 0;">
                <div v-if="detailData.state === '20'" class="inner_label">撤回时间：</div>
                <div v-else class="inner_label">关闭时间：</div>
                <div>{{ detailData.closeTime }}</div>
              </div>
              <div class="inner_line" style="margin-bottom: 0;"
                v-if="detailData.rejectMark === '00' || detailData.rejectMark === '01' || detailData.rejectMark === '02'">
                <div class="inner_label">操作人：</div>
                <div>{{ detailData.rejectAdminInfo }}</div>
              </div>
              <div class="inner_line" style="margin-bottom: 0;" v-if="detailData.rejectMark === '03'">
                <div class="inner_label">操作人：</div>
                <div>{{ detailData.rejectStaffInfo }}</div>
              </div>
              <div class="inner_line" style="margin-bottom: 0;" v-if="detailData.rejectMark === '04'">
                <div class="inner_label">撤回人：</div>
                <div>{{ detailData.cancelStaffInfo }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="store_box">
        <div class="store_line">
          <div class="style_line">
            <div>1</div>
            <div>门店审核</div>
            <div class="progress_bar"></div>
          </div>
          <div class="style_line" v-if="detailData.state === '10' || detailData.state === '20'">
            <div>2</div>
            <div>追差关闭</div>
            <!--            <div class="progress_bar"></div>-->
          </div>
          <template v-else>
            <div class="style_line">
              <div>2</div>
              <div>门店打款</div>
              <div class="progress_bar"></div>
            </div>
            <div class="style_line">
              <div>3</div>
              <div>追差完成</div>
              <!--              <div class="progress_bar"></div>-->
            </div>
          </template>
        </div>
        <div class="store_inner">
          <div class="store_main_style">
            <div v-if="detailData.state === '00'" class="store_result_style empty">
              <img src="../images/img_ddsh_.png" alt="">
              <p class="inner_label">等待审核</p>
            </div>
            <!--门店审核-->
            <div class="store_result_style" v-else>
              <div class="inner_line">
                <p class="inner_label">审核判责：</p>
                <p v-if="!(detailData.state === '10' || detailData.state === '20')">
                  通过追差（{{ detailData.undertakeSubjectText }}）</p>
                <p v-else>
                  <span v-if="detailData.state === '20'">回收商撤回追差</span>
                  <span v-else-if="detailData.cview.rejectMark === '02'">
                    门店打款驳回（回收商责任）
                  </span>
                  <span v-else-if="detailData.cview.rejectMark === '03'">
                    门店审核驳回（回收商责任）
                  </span>
                  <span v-else-if="detailData.cview.rejectMark === '04'">
                    驳回追差(回收商撤回申请)
                  </span>
                  <span v-else>驳回追差</span>
                </p>
              </div>
              <div class="inner_line">
                <p class="inner_label">审核人：</p>
                <p>{{ detailData.cview.auditStaffInfo || '--' }}</p>
              </div>
              <div class="inner_line">
                <p class="inner_label">审核时间：</p>
                <p>{{ detailData.cview.auditTime || '--' }}</p>
              </div>
              <div class="inner_line" v-if="!(detailData.state === '10' || detailData.state === '20')">
                <p class="inner_label">审核追差金额：</p>
                <p>￥{{ detailData.arguePrice || '--' }}</p>
              </div>
              <div class="inner_line" v-else>
                <p class="inner_label">驳回原因：</p>
                <p>{{ detailData.cview.rejectMarkText || '--' }}</p>
              </div>
            </div>
            <!-- 门店打款 -->
            <div class="store_result_style" v-if="detailData.state === '04'">
              <div class="inner_line">
                <p>已完成打款</p>
              </div>
              <div class="inner_line">
                <p class="inner_label">打款人：</p>
                <div v-if="detailData.payWay === '00'">{{ detailData.payStaffInfo || '--' }}</div>
                <div v-else-if="detailData.payWay === '01'">{{
                  detailData.auditReAdminInfo || '--'
                }}（平台直接操作）
                </div>
                <div v-else-if="detailData.payWay === '02'">超时系统自动打款</div>
                <div v-else>--</div>
              </div>
              <div class="inner_line">
                <p class="inner_label">打款时间：</p>
                <div v-if="detailData.payWay === '01'">{{ detailData.auditReAdminTime }}</div>
                <div v-else>{{ detailData.payTime }}</div>
              </div>
              <div class="inner_line">
                <p class="inner_label">已打款金额：</p>
                <p>￥{{ detailData.deStoreBalance || '--' }}<span v-if="detailData.moneyWay" :style="detailData.moneyWay==='01' ? 'color: green;' : 'color: red;'">({{detailData.moneyWay==='00'?'线下打款':'线上打款'  }})</span></p>
              </div>
            </div>
            <div v-else class="store_result_style empty"
              v-show="!(detailData.state === '10' || detailData.state === '20')">
              <img src="../images/img_ddsh_.png" alt="">
              <p class="inner_label">等待打款</p>
            </div>
            <!-- 追差完成 -->
            <div class="store_result_style" v-if="detailData.state === '04'">
              <div class="inner_line">
                <p>追差完成</p>
              </div>
            </div>
            <div v-else v-show="!(detailData.state === '10' || detailData.state === '20')"
              class="store_result_style empty">
              <img src="../images/img_ddsh_.png" alt="">
              <p class="inner_label">暂无数据</p>
            </div>
            <!-- 追差关闭 -->
            <div class="store_result_style" v-if="detailData.state === '10' || detailData.state === '20'">
              <div class="inner_line" v-if="detailData.state === '10'">
                <p>追差已关闭，请及时与回收商沟通</p>
              </div>
              <div class="inner_line" v-if="detailData.state === '20'">
                <p>回收商撤回追差，追差已关闭</p>
              </div>
              <div class="inner_line" v-if="detailData.state === '20'">
                <p class="inner_label">撤回人：</p>
                <p>{{ detailData.cancelStaffInfo || '--' }}</p>
              </div>
              <div class="inner_line" v-if="detailData.state === '20'">
                <p class="inner_label">撤回时间：</p>
                <p>{{ detailData.closeTime || '--' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 确认扣钱商户余额 -->
    <DialogAction v-bind="deBalanceVoucherProps" @close="deBalanceVoucherClose" @config="diffFundConfig">
      <template v-slot:from>
        <div class="money_original" style="margin-top: 0;">
          <label class="responsibility_title element_xing" for="arguePriceAudit">确认门店扣款金额：</label>
          <!-- 商户关闭或者当前是fx渠道或者普通订单时，最大值又是0，限制不可输入或者限制最大值 -->
          <!--          <el-input-->
          <!--              v-if="detailData.outType === 'fx' || detailData.outType === '&#45;&#45;'"-->
          <!--              style="width: 200px;margin-right: 12px;"-->
          <!--              name="arguePriceAudit"-->
          <!--              :value="deBalanceSumit.money"-->
          <!--              type="number"-->
          <!--              @input="v=>deBalanceSumit.money = getFloat2Value(v,detailData.outType === 'fx'?detailData.oriBalance:detailData.balance)"-->
          <!--              placeholder="请输入门店扣款金额"-->
          <!--              :disabled="deBalanceVoucherProps.isView"-->
          <!--              minlength="1"/>-->
          <el-input :disabled="deBalanceVoucherProps.isView" style="width: 200px;margin-right: 12px;"
            name="arguePriceAudit" :value="deBalanceSumit.money" type="number"
            @input="v => deBalanceSumit.money = getFloat2Value(v) <= 0 ? 0 : getFloat2Value(v)" placeholder="请输入门店扣款金额"
            minlength="1" />
          <span>元</span>
        </div>
        <p v-if="detailData.outType === 'fx'" style="color: #333;font-size: 12px">({{
          detailData.oriCompanyName || '--'
        }}：{{ detailData.oriBalance || '--' }}元)</p>
        <p v-else style="color: #333;font-size: 12px">({{ detailData.currentCompanyName || '--' }}：{{
          detailData.balance || '--'
        }}元)</p>
        <!-- 凭证 -->
        <div class="voucher_box element_xing">
          <p>上传沟通凭证</p>
          <div v-if="!deBalanceVoucherProps.isView">
            <!-- 上传 -->
            <UploadElement v-bind="{ existImage: deBalanceSumit.voucher }" :exist-image="deBalanceSumit.voucher"
              @handleDelete="deBalanceSumit.voucher = ''" @handleChange="v => deBalanceSumit.voucher = v" />
          </div>
          <div v-else>
            <el-image style="width: 118px; height: auto;border: 1px dashed #C1CBFF;" :src="deBalanceSumit.voucher"
              :preview-src-list="[deBalanceSumit.voucher]">
            </el-image>
          </div>
        </div>
      </template>
    </DialogAction>

    <!-- 驳回差异 -->
    <DialogAction v-bind="rejectProp" @close="rejectProp.show = false;" @config="rejectProp.show = false;">
      <template v-slot:from>
        <div class="rejetct_box">
          <div class="reject_duty">
            <div style="margin-right: 10px;">审批判责：</div>
            <el-radio disabled>回收商责任（驳回追差）</el-radio>
          </div>
          <div class="reject_basis">
            <JudgementsList :source-data="rejectProp.judgements" state="01" />
          </div>
        </div>
      </template>
    </DialogAction>
  </div>
</template>
<script>
import UploadElement from "@/components/global/components/elements/upload-element.vue";
import JudgementsList from "@/views/difference/componets/JudgementsList.vue";
import DialogAction from "@/components/common/DialogAction.vue";
import { imeiTestProps } from "@/views/RecallOrder/initState";
import {
  cs,
  deBalanceVoucherProps,
  responsibilityData,
  switchResponsibility,
} from "@/views/difference/platformReview/initState";
import ProcessHeader from "@/views/difference/componets/ProcessHeader.vue";
import {
  getAprState,
  getAprStateStore,
  getFloat2Value,
  getRejectMark,
  getUndertakeSubject,
  infoConcat,
  setValue
} from "@/utils/common";
import _api from "@/utils/request";

export default {
  components: { ProcessHeader, DialogAction, JudgementsList, UploadElement },
  data() {
    const resetData = [
      {
        url: "",
        reason: "",
        judgeType: ""
      }
    ];
    const { type } = this.$route.query;
    return {
      RemarkslogTxt: '',//备注内容
      // 打款方式
      moneyWay: '',
      allDiffFund: 0,//当前总差异基金
      merchantDiffFund: 0,//回收商总差异基金
      // 暂存
      temporaryShow: false,
      temporaReasonList: [
        { key: 1, name: '账号/密码未退，需先跟门店沟通' },
        { key: 2, name: '追差金额太高，需进一步核实' },
        { key: 3, name: '追差异常，需退回繁星仓库验机后处理' },
        { key: 4, name: '其他原因' },
      ],//暂存原因
      chooseTemReason: '',//选择的暂存原因
      otherReason: '',
      permitSeeOpen: true,//是否允许门店提前查看配置
      // *************
      temporaryLoading: false,//暂存
      imeiTestProps,
      // 共同承担，tab控制,01门店，02：回收商
      tabSquare: '',
      // 是否清除设置依据
      isResponsibilityReset: false,
      // 判责
      responsibilityData,
      //责任切换弹窗提示
      switchResponsibility,
      assignmentData: [],
      // 共同承担 门店依据
      storeJudgements: resetData,
      // 共同承担 回收商依据
      recoveryJudgements: resetData,
      // 上传依据直接赋值操作
      submitData: {
        rePayPrice: '',//复核金额
        deStoreBalance: null,//余额
        diffFundPrice: null,//差异基金
        undertakeSubject: "",//判责
        // 判责依据
        judgements: [],
        // 追差金额
        arguePriceAudit: "",
        // 回收商建议
        merchantSuggest: "",
        // 门店商建议
        companySuggest: ""
      },
      // 余额扣除
      isDeBalanceVoucher: false,
      // 差异基金开启状态
      isDiffFundPrice: true,
      // 差异基金提交数据
      deBalanceSumit: {
        voucher: "",
        money: null,
        isEdit: false,
      },
      deBalanceVoucherProps,
      // 订单号
      argueNo: "",
      // 详情数据
      detailData: {
        cview: {
          auditStaffInfo: ''
        }
      },
      // 订单信息
      infoData: {
        funcDetail: {},
        sku: { skus: [] },//外部规格
      },
      // 切换矩阵
      isMatrix: false,
      residueTime: "",
      // 清楚定时器
      timer: null,
      rejectProp: {
        show: false,
        title: '驳回依据',
        currentData: {},
        judgements: [],
        isReset: false
      },
      type,
      firstLoading: false,//初审
      checkLoading1: false,
      checkLoading2: false,
      checkLoading3: false,
    }
  },
  computed: {
    adminButton() {
      return this.$store.state.tagsView.adminButton
    },
    // 提交初审权限
    firstButtonShow() {
      const index = this.adminButton.findIndex(v => v.pageUrl === this.$route.path)
      if (index < 0) {
        return false;
      }
      const { buttonList } = this.adminButton[index];

      // 初审
      if (this.detailData.state === '00') {
        const i = buttonList.findIndex(v => v.buttonCode === 'Apr_Audit_First');
        return i >= 0;
      }
      return false;
    },
    checkButtonShow() {
      const index = this.adminButton.findIndex(v => v.pageUrl === this.$route.path);
      if (index < 0) {
        return false;
      }
      const { buttonList } = this.adminButton[index];
      // 复核
      if (this.detailData.state === '01') {
        const i = buttonList.findIndex(v => v.buttonCode === 'Apr_Audit_Re');
        return i >= 0;
      }
      return false;
    },
  },
  watch: {
    // 监听依据商户类型切换
    tabSquare(v) {
      // 门店
      if (v === '01') {
        this.assignmentData = this.storeJudgements;
      }
      // 回收商
      if (v === '02') {
        this.assignmentData = this.recoveryJudgements;
      }
    },
    // 余额的开通
    isDeBalanceVoucher(v) {
      if (v) {
        // if (Number(this.detailData.oriBalance) <= 0 && this.detailData.outType === 'fx') {
        //   this.isDeBalanceVoucher = false;
        //   return this.$message.error("当前的账户余额不足，不可设置");
        // }
        // if (Number(this.detailData.balance) <= 0 && this.detailData.outType === '--') {
        //   this.isDeBalanceVoucher = false;
        //   return this.$message.error("当前的账户余额不足，不可设置");
        // }
        this.deBalanceVoucherProps.isView = false;
        this.deBalanceVoucherProps = {
          ...this.deBalanceVoucherProps,
          isView: false,
          show: true,
          title: "确认扣款商户余额",
          headerMessage: "说明：请谨慎确认追差款项从商户余额扣款，并上传沟通凭证",
        };
      } else {
        this.deBalanceVoucherProps.isView = false;
        this.deBalanceSumit = {
          ...this.deBalanceVoucherProps,
          voucher: "",
          money: null,
          title: "确认扣款商户余额",
          headerMessage: "说明：请谨慎确认追差款项从商户余额扣款，并上传沟通凭证",
          isEdit: false
        };
        this.submitData.deStoreBalance = null;
      }
    },
    // 差异基金
    isDiffFundPrice(v) {
      console.log(this.detailData, '6666666');
      if (v) {
        // 矩阵原订单基金
        // 原：加了&& Number(this.detailData.oriDiffFundPriceAccount) <= 0
        if (this.detailData.state === '01' && this.detailData.outType === 'fx') {
          // this.isDiffFundPrice = false;
          // return this.$message.error("当前差异基金金额为0，不可设置")
        }
        // 订单基金
        // 原：this.detailData.state === '01' && this.detailData.state === '01' && this.detailData.outType === '--'&& Number(this.detailData.diffFundPriceAccount) <= 0
        if (this.detailData.outType !== 'fx' && this.detailData.outType !== '--' && Number(this.detailData.diffFundPriceAccount) <= 0) {
          console.log('111asd222222');
          this.isDiffFundPrice = false;
          return this.$message.error("当前差异基金金额为0，不可设置")
        }
        this.submitData.diffFundPrice = "";
      } else {
        this.submitData.diffFundPrice = null;
      }
    }
  },
  created() {
    const { argueNo } = this.$route.query;
    this.argueNo = argueNo;
    if (this.type === 'store') {
      this.getStoreData();
    } else {
      this.getData();
    }
  },
  mounted() {
    this.updateCountdown();
    this.timer = setInterval(this.updateCountdown, 1000); // 每秒更新一次倒计时
  },
  // 卸载前清楚定时器
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 刷新
    refreshData() {
      // 总差异基金
      this.getAllDiffFund()
      this.getMerchantDiffFund()
    },
    // 获取当前总差异基金 this.infoData
    getAllDiffFund() {
      _api.getAllDiffFund().then(res => {
        if (res.code === 1) {
          console.log(res, '当前总差异基金');
          this.allDiffFund = res.data
        }
      })
    },
    // 回收商总差异基金
    getMerchantDiffFund() {
      _api.merchantDiffFund({ merchantId: this.infoData.merchantId }).then(res => {
        this.merchantDiffFund = res.data
      })
    },
    // 复核打款-差异基金可扣成负数
    inputDiffFundPrice(v) {
      console.log(this.detailData, 'hahahaha哈哈啊哈');
      if (this.detailData.outType !== 'fx' && this.detailData.outType !== '--') {
        if (this.detailData.diffFundPriceAccount <= 0) {
          this.submitData.diffFundPrice = 0
        }
      }
    },
    // ******************************
    // 是否允许门店提前查看配置
    permitSee(e, row) { },
    // 暂存
    temporaryStore() {
      this.temporaryShow = true
    },
    // 关闭暂存
    temporaryClose() {
      this.temporaryShow = false
    },
    // 提交暂存原因
    temporarySubmit() {
      // 请求
      const params = {
        argueNo: this.argueNo,
        staCause: this.chooseTemReason == '其他原因' ? this.otherReason : this.chooseTemReason
      }
      _api.temporary(params).then(res => {
        console.log(res, '暂存2222222222');
        if (res.code === 1) {
          this.temporaryShow = false
          // 平台差异订单列表 暂存的 state
          let activeIndex = '5'
          window.location.href = `/difference/platformReview?activeIndex=${activeIndex}`, "_blank";
        }
      })
    },
    // ************************
    getRejectMark,
    // 限制数字，保留两位数
    getFloat2Value,
    // 平台差异单详情
    getData() {
      _api.aprDetatil({ argueNo: this.argueNo }).then(r => {
        let data = r.data ?? {};
        data = {
          ...data,
          vouchers: Array.isArray(data.vouchers) ? data.vouchers : [],
          stateText: getAprState(data.state),
          applyStaffInfo: infoConcat(data.applyStaffName, data.applyStaffMobile),
          items: Array.isArray(data.items) ? data.items.join(",") : data.items,
          auditFirstInfo: infoConcat(data.auditFirstAdminName, data.auditFirstAdminAcc),
          auditReAdminInfo: infoConcat(data.auditReAdminName, data.auditReAdminAcc),
          rejectAdminInfo: infoConcat(data.rejectAdminName, data.rejectAdminAcc),
          rejectStaffInfo: infoConcat(data.rejectStaffName, data.rejectStaffMobile),
          cancelStaffInfo: infoConcat(data.cancelStaffName, data.cancelStaffPhone),
          payStaffInfo: infoConcat(data.payStaffName, data.payStaffMobile),
        }
        this.detailData = setValue(data);
        this.getInfo(data.orderNo);
        this.resultEcho();
        // 新增：外部渠道订单 差异基金默认关闭,其他默认开启
        console.log(this.detailData, 'hahaha');
        if (this.detailData.outType === 'fx' || this.detailData.outType === '--') {
          // outType的值不是'fx'或null的处理逻辑
          this.isDiffFundPrice = true
        } else {
          // outType的值是'fx'或null的处理逻辑
          this.isDiffFundPrice = false
        }
        // 商户关闭或者当前是fx渠道或者普通订单时，最大值又是0，限制不可输入或者限制最大值
        // 原：加了&& Number(this.detailData.oriDiffFundPriceAccount) <= 0
        // if (this.detailData.state === '01' && this.detailData.outType === 'fx') {
        //   this.isDiffFundPrice = false;
        // }
        // if (this.detailData.state === '01' && this.detailData.outType === '--' && Number(this.detailData.diffFundPriceAccount) <= 0) {
        //   this.isDiffFundPrice = false;
        // }
        if (data.outType === 'fx') {
          this.isMatrix = true;
        } else {
          this.isMatrix = false;
        }
      });
    },
    // 门店差异单详情
    getStoreData() {
      _api.aptStoreDetail({ argueNo: this.argueNo }).then(r => {
        let data = r.data ?? {};
        let cview = data.cview ?? {};
        data = {
          ...data,
          auditReAdminInfo: infoConcat(data.auditReAdminName, data.auditReAdminAcc),
          vouchers: Array.isArray(data.vouchers) ? data.vouchers : [],
          stateText: getAprStateStore(data.state),
          items: Array.isArray(data.items) ? data.items.join(",") : data.items,
          applyStaffInfo: infoConcat(data.applyStaffName, data.applyStaffMobile),
          // 打款人
          payStaffInfo: infoConcat(data.payStaffName, data.payStaffMobile),
          cancelStaffInfo: infoConcat(data.cancelStaffName, data.cancelStaffPhone),
          cview: {
            ...data.cview,
            //审核人
            auditStaffInfo: infoConcat(cview.auditStaffName, cview.auditStaffMobile),
            rejectMarkText: getRejectMark(cview.rejectMark),
          },
          undertakeSubjectText: getUndertakeSubject(data.undertakeSubject)
        }
        data = setValue(data);
        this.detailData = data;
        this.getInfo(data.orderNo);
      })
    },
    // 倒计时
    updateCountdown() {
      const value = Number(this.detailData.unPayCountdownSecond);
      if (value <= 0) {
        this.residueTime = `00:00:00`;
        return clearInterval(this.timer);
      }
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const seconds = value % 60;
      this.residueTime = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
      this.detailData.unPayCountdownSecond = value - 1;
    },
    pad(value) {
      value = Number(value);
      return value < 10 ? '0' + value : value;
    },
    // 父组件统一管理数据(订单信息)
    getInfo(orderNo) {
      _api.orderInfo({ orderNo }).then(r => {
        let data = r.data ?? {};
        data = {
          ...this.imeiTestProps,
          ...data,
          funcDetail: data.funcDetail ?? { machineName: '--' },
          // *********新加的
          sku: data.sku ?? { skus: [] },//外部规格
          functionList: data.coreFaults ?? [],//功能列表
          isSystem: data.detectionType === '02' && data.detectionInfo,//检测类型
          unableDetectionCause: data.unableDetectionCause,//是否系统检测
          detectionInfo: data.detectionInfo ?? {},//检测结果
          orderMode: data.orderMode,//预估价模式
          phoneName: data.phoneName,
          remark: data.remark,//下单备注
          againPhotoRemark: data.againPhotoRemark,//补拍备注
          // *********************
          staffInfo: infoConcat(data.staffName, data.staffMobile),
          images: data.images.map(v => {
            return {
              mediaUrl: v.originalImg,
              mediaType: 'image',
              imgWay:v.imgWay
            }
          })
        }
        console.log(data.reImages,"补拍图片")
        if(data.reImages){
          data.reImages.forEach(item=>{
            data.images.unshift({
              mediaUrl: item.originalImg,
              mediaType: 'image',
              imgWay:item.imgWay
            })
          })
        }
        this.infoData = setValue(data);
        this.setFunctionType(data);
        this.setFunctionData(data);
        // 总差异基金
        this.getAllDiffFund()
        this.getMerchantDiffFund()
      })
    },
    //功能情况设置显示值
    setFunctionType(v) {
      let { isSystem, unableDetectionCause, functionList, isOtherTitileShow } = this.imeiTestProps;
      // 其他功能 系统检测||无法检测&&功能项存在&&（不是采货侠或者是采货侠订单且外部渠道为fx）
      if ((isSystem || unableDetectionCause) && functionList.length && (!v.isOneline || (v.isOneline && v.outType === 'fx'))) {
        isOtherTitileShow = true;
      } else {
        isOtherTitileShow = false;
      }
      this.imeiTestProps.isOtherTitileShow = isOtherTitileShow;
      // 02不是采货侠或者是采货侠订单且外部渠道为fx
      if (!v.isOneline || (v.isOneline && v.outType === 'fx')) {
        return this.imeiTestProps.type = '02';
      }
    },

    // 上述02类型  外部渠道盛达lb或者lb2，屏蔽正常开机选项
    setFunctionData(v) {
      const fineness = v.fineness ?? { fineness: [] };
      v.detectionInfo = v.detectionInfo ?? {};
      let { isFinenessShow, functionList, systemTestList, photoArr, allSystemTestList } = this.imeiTestProps;
      const { cameraTestImgFront, cameraTestImgRear } = v.detectionInfo;
      systemTestList = JSON.parse(JSON.stringify(allSystemTestList));
      let finenessData = Array.isArray(fineness.fineness) ? fineness.fineness : [];
      functionList = Array.isArray(functionList) ? functionList : [];
      // 盛达外部去除是否开机
      if (v.outType === 'lb' || v.outType === 'lb2') {
        functionList = functionList.filter(v => v.faultName !== '是否正常开机');
      }
      // 成色渲染 是采货侠 && 并且存在成色数据
      if (v.isOneline && fineness && finenessData.length) {
        isFinenessShow = true;
        finenessData = Array.isArray(finenessData) ? finenessData : [];
        finenessData = finenessData.map(item => {
          if (v.outType === 'lb' || v.outType === 'lb2') {
            item.value = item.value ?? [];
            item.value = item.value.map(v => {
              return {
                valueText: v.isNormal ? '正常' : '异常',
                isNormal: v.isNormal
              }
            })
          }
          return {
            name: item.label,
            value: item.value
          }
        })
      } else {
        isFinenessShow = false;
      }
      // 设置功能机
      functionList = functionList.map(v => {
        return {
          name: v.faultName,
          state: v.faultAnswer,
          reason: v.faultCause
        }
      });
      // 检测类型动态更改值的key
      const systemKeys = ['mobileNetworks', 'wlan', 'bluetooth'];
      if (v.detectionInfo) {
        // 设置系统验机项
        systemTestList = systemTestList.map(item => {
          item.value = v.detectionInfo[item.key] ?? '--';
          //前三个，正常的就是系统验机，异常的话是人工验机
          if (systemKeys.indexOf(item.key) >= 0) {
            if (v.detectionInfo[item.key] === '01') {
              item.isSystem = true;
            } else {
              item.isSystem = false;
            }
          }
          // 存在值才进行替换
          if (v.detectionInfo[item.tipKey]) {
            item.tips = v.detectionInfo[item.tipKey].replaceAll("&", "       ")
          }
          return item
        });
      }


      // 设置前置旧机照片
      if (cameraTestImgFront) {
        photoArr.push(cameraTestImgFront)
      }
      // 设置后置
      if (cameraTestImgRear) {
        photoArr.push(cameraTestImgRear)
      }
      this.imeiTestProps = {
        ...this.imeiTestProps,
        functionList,
        isFinenessShow,
        finenessData,
        systemTestList,
        photoArr,
      };
    },
    // 值回显
    resultEcho() {
      let { state, undertakeSubject, judgements, merchantSuggest, companySuggest, arguePriceAuditS } = this.detailData;
      if (state !== '00') {
        this.submitData = {
          ...this.submitData,
          undertakeSubject,
          merchantSuggest,
          companySuggest,
          arguePriceAudit: arguePriceAuditS
        }
        judgements = Array.isArray(judgements) ? judgements : [];
        judgements = judgements.map(v => {
          return {
            ...v,
            url: v.mediaUrl,
          }
        })
        // 责任
        if (undertakeSubject !== 'T') {
          this.submitData.judgements = judgements;
        } else {
          this.tabSquare = '01';
          this.storeJudgements = judgements.filter(v => v.judgeType === '00');
          this.recoveryJudgements = judgements.filter(v => v.judgeType === '01');
          this.submitData.judgements = this.storeJudgements;
        }
      }
    },
    // 切换弹窗显示操作
    setsSwitchDialog(v) {
      this.switchResponsibility = {
        ...this.switchResponsibility,
        newValue: v.value,
        show: true
      };
    },
    // 上传依据切换
    responsibilityChange(v) {
      if (this.detailData.state !== '00') {
        return this.$message.error('已提审，不可操作');
      }
      // 不存在驳回权限
      if (this.detailData.state === '00' && v.value === 'M') {
        const pathIndex = this.adminButton.findIndex(v => v.pageUrl === this.$route.path)
        const { buttonList } = this.adminButton[pathIndex];
        const i = buttonList.findIndex(v => v.buttonCode === 'Apr_Reject');
        if (i < 0) {
          return this.$message.error("当前无驳回操作权限")
        }
      }
      // 不清除其内的数据
      this.isResponsibilityReset = false;
      const { judgements, undertakeSubject } = this.submitData;
      const { storeJudgements, recoveryJudgements, tabSquare } = this;
      // 每次切换到双方共同责任，清楚之前选择的项
      if (v.value === "T") {
        this.tabSquare = "";
      }
      // // 从未选择责任
      if (!undertakeSubject) {
        return this.submitData.undertakeSubject = v.value;
      }
      // 点击相同项
      if (undertakeSubject === v.value) {
        return;
      }
      if (undertakeSubject !== 'T') {
        // 如果当前依据项的值为空
        if (!judgements.length) {
          return this.submitData.undertakeSubject = v.value;
        }
        // 添加过依据或者第一条存在数据，那么切换给提示（针对门店或者回收商承责）
        if (judgements.length > 1 || judgements[0].reason || judgements[0].url) {
          return this.setsSwitchDialog(v);
        }
      }
      // 共同承担
      if (this.submitData.undertakeSubject === 'T' && tabSquare) {
        // 针对双方共同责任(门店)
        if (storeJudgements.length > 1 || storeJudgements[0].reason || storeJudgements[0].url) {
          return this.setsSwitchDialog(v);
        }
        // 针对双方共同责任（回收）添加过依据或者第一条存在数据，那么切换给提示（针对者门店商承责）
        if (recoveryJudgements.length > 1 || recoveryJudgements[0].reason || recoveryJudgements[0].url) {
          return this.setsSwitchDialog(v);
        }
        // 针对双方共同责任（回收）添加过依据或者第一条存在数据，那么切换给提示（针对者回收商承责）
        // if (recoveryJudgements.length > 1 || recoveryJudgements[0].reason || recoveryJudgements[0].url) {
        //   return this.setsSwitchDialog(v);
        // }
        // return this.submitData.undertakeSubject = v.value;
      }
      return this.submitData.undertakeSubject = v.value;
    },

    // 切换弹窗责任确定操作
    switchResponsibilityConfig() {
      this.submitData.undertakeSubject = this.switchResponsibility.newValue;
      this.switchResponsibility.show = false;
      // 双方共同承担，组件没渲染，重置会无效
      const resetData = [
        {
          url: "",
          reason: "",
          judgeType: ""
        }
      ];
      this.recoveryJudgements = resetData;
      this.storeJudgements = resetData;
      this.submitData.judgements = resetData;
      this.isResponsibilityReset = true;
    },
    // 依据上传项变化
    judgementsChange(v) {
      // 当前共同承担tab值
      const tabSquare = this.tabSquare;
      // 当前承责商
      const { undertakeSubject } = this.submitData;
      // 门店商
      if (undertakeSubject === 'C') {
        this.submitData.judgements = v.map(v => {
          return {
            ...v,
            judgeType: '00'
          }
        });
      }
      // 回收
      if (undertakeSubject === 'M') {
        this.submitData.judgements = v.map(v => {
          return {
            ...v,
            judgeType: '01'
          }
        })
      }
      // 共同
      if (undertakeSubject === 'T') {
        if (tabSquare === '01') {
          this.storeJudgements = v.map(v => {
            return {
              ...v,
              judgeType: '00'
            }
          });
          this.assignmentData = this.storeJudgements;
        }
        if (tabSquare === '02') {
          this.recoveryJudgements = v.map(v => {
            return {
              ...v,
              judgeType: '01'
            }
          });
          this.assignmentData = this.recoveryJudgements;
        }
      }
    },
    // 提交（初审）
    firstSubmit() {
      let submitData = JSON.parse(JSON.stringify(this.submitData));
      let request;
      if (this.submitData.undertakeSubject === 'T') {
        submitData.judgements = [...this.recoveryJudgements, ...this.storeJudgements];
      }
      const { judgements, undertakeSubject, arguePriceAudit } = submitData;
      const flag = judgements.every(v => v.reason && v.url);
      if (!undertakeSubject) {
        return this.$message.error("请选择判责方")
      }
      if (undertakeSubject === 'T') {
        if (!judgements.some(v => v.judgeType === '00' && v.reason && v.url)) {
          return this.$message.error("请填写完整门店责任依据");
        }
        if (!judgements.some(v => v.judgeType === '01' && v.reason && v.url)) {
          return this.$message.error("请填写完整回收商责任依据")
        }
      }
      if (!flag || !judgements.length) {
        return this.$message.error("请填写完整追责依据信息");
      }
      if (undertakeSubject === 'C' && !submitData.companySuggest) {
        return this.$message.error("请输入门店商验机建议");
      }
      if (undertakeSubject === 'M' && !submitData.merchantSuggest) {
        return this.$message.error("请输入回收商报价建议");
      }
      if (undertakeSubject === 'T' && !submitData.companySuggest) {
        return this.$message.error("请输入门店商验机建议");
      }
      if (undertakeSubject === 'T' && !submitData.merchantSuggest) {
        return this.$message.error("请输入回收商报价建议");
      }
      if (undertakeSubject !== 'M') {
        if (!arguePriceAudit) {
          return this.$message.error("请输入初审追差金额");
        }
      }
      submitData = {
        ...submitData,
        argueNo: this.argueNo,
        isCompanySee: this.permitSeeOpen //新增
      }
      // 是否驳回
      if (undertakeSubject === 'M') {
        // 矩阵
        // if (this.isMatrix) {
        //   request = _api.aprtRejectOut(submitData);
        // } else {
        //   request = _api.aprRejecy(submitData);
        // }
        request = _api.aprAuditFirst(submitData);

      } else {
        // 矩阵
        // if (this.isMatrix) {
        //   request = _api.auditFirstOut(submitData)
        // } else {
        //   request = _api.aprAuditFirst(submitData);
        // }
        request = _api.aprAuditFirst(submitData);
      }
      this.firstLoading = true;
      request.then(r => {
        this.$message.success("操作成功");
        // 返回上一页
        window.location.href = `/difference/platformReview`;
      }).finally(() => {
        this.firstLoading = false;
      })
    },
    // 余额确定开启
    diffFundConfig() {
      const { money, voucher } = this.deBalanceSumit;
      if (this.deBalanceVoucherProps.isView) {
        return this.deBalanceVoucherProps.show = false;
      }
      if (!money && money !== 0) {
        return this.$message.error("请输入扣款金额");
      }
      if (!voucher) {
        return this.$message.error("请选择上传凭证")
      }
      this.deBalanceVoucherProps.show = false;
      this.isDeBalanceVoucher = true;
      this.deBalanceSumit.isEdit = true;
      this.submitData.deStoreBalance = this.deBalanceSumit.money;
      this.submitData.deBalanceVoucher = this.deBalanceSumit.voucher;
    },
    // 关闭余额弹窗
    deBalanceVoucherClose() {
      this.deBalanceVoucherProps.show = false;
      this.deBalanceSumit.money = this.submitData.deStoreBalance;
      this.deBalanceSumit.voucher = this.submitData.deBalanceVoucher;
      if (this.isDeBalanceVoucher && !this.deBalanceSumit.isEdit) {
        this.isDeBalanceVoucher = false;
      }
    },
    // 切换矩阵
    matrixSwitch() {
      let orderNo = "";
      this.isMatrix = !this.isMatrix;
      if (this.isMatrix) {
        orderNo = this.detailData.orderNo;
      } else {
        orderNo = this.detailData.orderNoOneline;
      }
      this.getInfo(orderNo)
    },
    //复核
    checkSubmit(rePayChannel) {
      const data = JSON.parse(JSON.stringify(this.submitData));
      let request;
      const { deBalanceVoucher, deStoreBalance, diffFundPrice, rePayPrice } = data;
      // 限制了最大值，且开启了差异基金，就需要必传
      if (!(this.infoData.outType === 'fx' || this.infoData.outType === '--') && !diffFundPrice && this.isDiffFundPrice) {
        return this.$message.error("请输入使用差异金额")
      }
      // 修改 20231012
      if (this.isDiffFundPrice && this.submitData.diffFundPrice <= 0 && this.moneyWay==='01') {
        return this.$message.error("使用的差异金额不能小于等于0")
      }
      //复核打款金额
      if (!rePayPrice) {
        return this.$message.error("请输入复核打款金额")
      }
      // if (!this.RemarkslogTxt) {
      //   return this.$message.error("请输入差异订单备注")
      // }
      let bodyData = {
        // argueNo: this.argueNo,
        // moneyWay: this.moneyWay,//打款方式
        // deBalanceVoucher,
        // deStoreBalance,
        // diffFundPrice,
        // rePayPrice,
        // rePayChannel
      }
      if (this.moneyWay === '00') {//线下
        bodyData = {
          argueNo: this.argueNo,
          moneyWay: this.moneyWay,//打款方式
          offlinePrice:rePayPrice,//线下打款金额
          note:this.RemarkslogTxt,//差异订单备注
          deBalanceVoucher,
          // deStoreBalance,
          // diffFundPrice,
          rePayPrice,
          // rePayChannel
        }
      } else {
        bodyData = {
          argueNo: this.argueNo,
          moneyWay: this.moneyWay,//打款方式
          note:this.RemarkslogTxt,//差异订单备注
          deBalanceVoucher,
          deStoreBalance,
          diffFundPrice,
          rePayPrice,
          rePayChannel
        }
      }
      // 复核
      // if (this.isMatrix) {
      //   request = _api.auditReOut(bodyData);
      // } else {
      //   request = _api.auditRe(bodyData);
      // }
      request = _api.auditRe(bodyData);
      this.checkLoading2 = true;
      this.checkLoading1 = true;

      request.then(r => {
        this.$message.success('操作成功');
        // this.$router.go(-1);
        // 返回上一页
        window.location.href = `/difference/platformReview`;
      }).finally(() => {
        this.checkLoading1 = false;
        this.checkLoading2 = false;
      })
    },
    // 查看沟通凭证
    viewVoucher() {
      this.deBalanceVoucherProps = {
        ...this.deBalanceVoucherProps,
        show: true,
        isView: true,
        title: "沟通凭证",
        headerMessage: "",
      }
      this.deBalanceSumit = {
        ...this.deBalanceSumit,
        voucher: this.detailData.deBalanceVoucher,
        money: this.detailData.deStoreBalance
      }
    },
    //查看判断依据
    viewJudgements() {
      let judgementsResult = [];
      let { judgementsReject, judgements } = this.detailData;
      // 初审驳回
      if (this.detailData.rejectMark === '00') {
        judgements = Array.isArray(judgements) ? judgements : [];
        judgementsResult = judgements.map(v => {
          return {
            ...v,
            url: v.mediaUrl,
            state: '00'
          }
        })
      } else {
        // 复核或者打款驳回
        judgementsReject = Array.isArray(judgementsReject) ? judgementsReject : [];
        judgementsResult = judgementsReject.map(v => {
          return {
            ...v,
            url: v.mediaUrl,
            state: '00'
          }
        })
      }
      this.rejectProp = {
        ...this.rejectProp,
        show: true,
        judgements: judgementsResult
      }
    },
    //利润计算
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
  },
}
</script>
<style scoped lang="scss">
.statatics {
  margin-left: 40px;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.permit_tex {
  color: #0981FF;
  font-weight: 700;
  margin-right: 30px;
}

.permit_see {
  margin-top: 17px;
  width: 100%;
  background: #F5F6FA;
  border-radius: 4px;
  padding: 21px 26px 23px 20px;
  box-sizing: border-box;

  overflow: auto;

  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    // right: 4px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 22px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }
}

.temporary {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.box {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    >img {
      width: 30px;
      height: 30px;
    }

    >p {
      font-size: 18px;
      font-weight: 700;
      color: #333333;
      margin-left: 6px;
      margin-right: 6px;
    }

    >span {
      font-size: 16px;
      font-weight: 400;
      color: #FF8A00;
    }
  }
}

.examine_box {
  margin-top: 18px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 17px 20px 35px 20px;
  box-sizing: border-box;
}

.examine {
  // margin-top: 18px;
  // width: 100%;
  // background: #FFFFFF;
  // border-radius: 4px;
  // padding: 17px 20px 35px 20px;
  // box-sizing: border-box;
  display: flex;

  .examine-inner {
    flex: 1;
    min-width: 50%;
    max-width: 50%;
    width: 50%;
  }

  .examine_left {
    min-width: calc(50% - 22px);
    max-width: calc(50% - 22px);
    width: calc(50% - 22px);
    box-sizing: content-box;
    padding-right: 22px;
  }

  .examine_main {
    margin-top: 17px;
    width: 100%;
    background: #F5F6FA;
    border-radius: 4px;
    padding: 21px 26px 23px 20px;
    box-sizing: border-box;
    max-height: 400px;
    min-height: 400px;
    height: 400px;
    overflow: auto;
  }

  .examine_right_input {
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    margin-bottom: 20px;

    .examine_right_title {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }

    textarea {
      margin-top: 16px;
      width: 100%;
      height: 120px;
      padding: 12px 13px;
      box-sizing: border-box;
      background: #F9FBFD;
      border: 1px solid #C1D1FF;
      border-radius: 4px;

      &:focus {
        outline: none;
      }
    }
  }
}

.responsibility {
  display: flex;
  align-items: center;

  .responsibility_select {
    display: flex;
    align-items: center;

    >div {
      margin-right: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    img {
      margin-right: 6px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
}

.responsibility_category {
  width: 196px;
  height: 30px;
  box-sizing: content-box;
  background: #F9FBFD;
  border: 1px solid #C1D1FF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 24px;

  >p {
    width: 50%;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    background: #F9FBFD;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
    cursor: pointer;
  }

  .responsibility_category_active {
    background: #4C84FF;
    color: #FFFFFF;

  }
}

.money_original {
  margin-top: 24px;
  font-size: 14px;
}

.judgements_box {
  margin-top: 24px;
}

.responsibility_title {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.click_hover:hover {
  opacity: 0.8;
}

.info_title {
  font-size: 16px;
  font-weight: 600;
  color: #4C84FF;
  position: relative;
  padding-left: 7px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 2px;
    height: 14px;
    background: #4C84FF;
    border-radius: 1px;
  }
}

.check_money_box {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 19px 22px 25px 20px;
  box-sizing: border-box;
  margin-top: 18px;
  width: 100%;

  >.check_money_title {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #FD677D;
  }

  >.check_money_main {
    width: 100%;
    background: #F5F6FA;
    border-radius: 4px;
    padding: 24px 35px 31px 21px;
    box-sizing: border-box;
  }
}

.check_money_money {
  color: #333;
  font-size: 14px;
  margin-left: 29px;

  >span {
    color: #999;
  }
}

.element_xing {
  position: relative;
  padding-left: 8px;

  &::after {
    content: "*";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
    color: #F80000;
  }
}

.voucher_box {
  margin-top: 30px;

  >p {
    margin-top: 10px;
    color: #333;
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.check_money_btn {
  margin-top: 28px;
  display: flex;
  align-items: center;
  padding-left: 8px;

  >div {
    margin-right: 100px;
    cursor: pointer;
  }

  .direct_btn {
    width: 110px;
    height: 40px;
    background: #FD677D;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    position: relative;
    text-align: center;
    line-height: 40px;

    &:after {
      content: "谨慎操作";
      position: absolute;
      z-index: 1;
      right: -47px;
      height: 20px;
      line-height: 20px;
      top: -14px;
      padding: 0px 10px 0px 8px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      background: #FFAF29;
      border-radius: 10px 10px 10px 0px;
    }
  }

  .submit_btn {
    width: 100px;
    height: 40px;
    background: #4C84FF;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    position: relative;
    text-align: center;
    line-height: 40px;

    &:after {
      content: "先由门店确认打款";
      position: absolute;
      z-index: 1;
      right: -97px;
      height: 20px;
      line-height: 20px;
      top: -14px;
      padding: 0px 10px 0px 8px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      background: #FFAF29;
      border-radius: 10px 10px 10px 0px;
    }
  }

  .submit_btn {
    width: 100px;
    height: 40px;
    background: #4C84FF;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    position: relative;
    text-align: center;
    line-height: 40px;

    &:after {
      content: "先由门店确认打款";
      position: absolute;
      z-index: 1;
      right: -97px;
      height: 20px;
      line-height: 20px;
      top: -14px;
      padding: 0px 10px 0px 8px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      background: #FFAF29;
      border-radius: 10px 10px 10px 0px;
    }
  }

  .no_submit_btn {
    &::after {
      content: '本单门店无需打款';
      background: #21D8C2;
    }
  }
}

.offline_submit {
  cursor: pointer;
  margin-top: 28px;
  margin-left: 15px;
  width: 100px;
  height: 40px;
  background: #4C84FF;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  position: relative;
  text-align: center;
  line-height: 40px;

  &:after {
    content: "线下已打款";
    position: absolute;
    z-index: 1;
    right: -78px;
    height: 20px;
    line-height: 20px;
    top: -14px;
    padding: 0px 10px 0px 8px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: red;
    text-align: center;
    // background: #FFAF29;
    border: 1px solid red;
    border-radius: 10px 10px 10px 0px;
  }
}

.result_data {
  display: flex;
  width: 100%;
  background: #fff;
  margin-top: 43px;
  padding: 17px 20px 30px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: space-between;
  flex-wrap: wrap;

  >div {
    margin-bottom: 22px;
    flex: 1;
    width: 49%;
    max-width: 49%;
    min-width: 49%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .result_data_main {
    width: 100%;
    background: #F5F6FA;
    border-radius: 4px;
    padding: 26px 28px 24px 19px;
    box-sizing: border-box;
  }
}

.inner_line {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  font-size: 14px;
}

.inner_href_text {
  color: #4C84FF;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

/* 设置滚动条的宽度、高度、背景色和边框样式 */
::-webkit-scrollbar {
  width: 12px;
}

/* 设置滚动条滑块的背景色和圆角 */
::-webkit-scrollbar-thumb {
  background-color: #DCE0EE;
  height: 10px;
  border-radius: 4px;
}

/* 设置滚动条滑块在悬停状态下的背景色和圆角 */
//::-webkit-scrollbar-thumb:hover {
//  height: 10px;
//}

/* 设置滚动条轨道的背景色和圆角 */
::-webkit-scrollbar-track {
  background-color: rgba(220, 224, 238, .5);
}

/* 设置滚动条轨道在悬停状态下的背景色和圆角 */
//::-webkit-scrollbar-track:hover {
//  background-color: #ccc;
//  border-radius: 5px;
//}

//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type='number'] {
  -moz-appearance: textfield;
}

.inner_title_red {
  color: #FD677D;
}

.inner_label {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.inner_href_text {
  color: #4C84FF;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}


.disable_result {
  border-color: #eee !important;
  background: #eee !important;
  cursor: no-drop !important;
}

.submit_box {
  margin-top: 27px;
}

.rejetct_box {
  .reject_duty {
    display: flex;
    align-items: center;
  }

  .reject_basis {
    margin-top: 10px;

    /deep/ .box .list_box p {
      width: 80px;
    }
  }
}

.store_box {
  background: #fff;
  padding: 16px 20px 31px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-top: 25px;

  .store_line {
    display: flex;
    align-items: center;
    margin-right: 6px;

    .style_line {
      max-width: 414px;
      width: 414px;
      min-width: 414px;
      display: flex;
      align-items: center;

      >div {
        flex: 1;
      }

      >div:first-of-type {
        max-width: 15px;
        max-height: 15px;
        text-align: center;
        background: #4C84FF;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        margin-right: 4px;
      }

      >div:nth-of-type(2) {
        max-width: 67px;
        font-size: 16px;
        font-weight: 400;
        color: #4C84FF;
      }

      >.progress_bar {
        flex: 1;
        height: 2px;
        background: #4C84FF;
        margin-right: 4px;
      }
    }
  }

  .store_main_style {
    display: flex;
    margin-top: 18px;

    .store_result_style {
      min-width: 392px;
      max-width: 392px;
      width: 392px;
      padding: 23px 18px 19px 21px;
      box-sizing: border-box;
      background: #F5F6FA;
      border-radius: 4px;
      margin-right: 22px;

      >div:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.empty {
  text-align: center;

  img {
    width: 96px;
    height: 50px;
    margin: 0 auto;
    margin-top: 30px;
    display: block;
  }
}

.check_money_btn>div.loading {
  position: relative;
  cursor: default;
}

.check_money_btn>div.loading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  //transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .2);
  //border: 2px solid white;
  //border-top: 2px solid transparent;
  //border-radius: 50%;
  //animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>

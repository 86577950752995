<!--轮播 -->
<template>
  <div class="switer_list">
    <div class="switer_left switer_icon" @click="leftClick">
      <i class="el-icon-arrow-left"/>
    </div>
    <div style="overflow: hidden;width: 870px">
      <div class="switer_list_box" :style="videoSliderStyles" style="" v-if="switerList.length>0">
        <div class="switer_inner" v-for="(item,index) in switerList" :key="index">
          <!-- 视频遮罩 -->
          <div class="video_mask"
               :style="{display:item.mediaType === 'video'?'block':'none'}"
               @click="videoPreview(item.mediaUrl)"
          >
            <i class="el-icon-caret-right"></i>
          </div>
          <!-- 图片 -->
          <video class="mini_show" v-if="item.mediaType === 'video'">
            <source :src="item.mediaUrl">
          </video>
<!--          <viewer :images="imgUrl" v-else>-->
            <div v-else style="display: inline-block;position: relative;">
              <div v-if="item.imgWay" class="reshotClass">补拍</div>
              <img
                @click="imgPreview(item.mediaUrl)"
                class="image_show"
                :src="item.mediaUrl"/>
            </div>
<!--          </viewer>-->
        </div>
      </div>
      <div v-else class="empty">
        <img src="../images/img_ddsh_.png" alt="">
        <p>未上传任何视频或图片</p>
      </div>
    </div>
    <div class="switer_rigth switer_icon" @click="rightClick">
      <i class="el-icon-arrow-right"/>
    </div>
    <!--  视频预览  -->
    <div class="video_preview" v-if="videoShow">
      <div class="video_close" @click="videoShow = false">
        <i class="el-icon-close"/>
      </div>
      <video controls autoplay>
        <source :src="videoUrl">
        视频格式不支持
      </video>
    </div>
  </div>
</template>

<script>
import details from "@/views/RecallOrder/Details.vue";

export default {
  name: "Carousel",
  data() {
    return {
      // 轮播
      videoSwiterData: {
        // 当前索引
        index: 0,
        // 移动位置
        x: 0
      },
      // video预览
      videoShow: false,
      videoUrl: "",
    }
  },
  props: {
    switerList: {
      type: Array,
      default: () => []
    }
  },
  // watch:{
  //   switerList(v){
  //     let imgUrl = v.filter(v=>v.mediaType !== 'video');
  //     this.imgUrl = imgUrl.map(v=>v.mediaUrl);
  //     console.log(this.imgUrl)
  //   }
  // },
  computed: {
    details() {
      return details
    },
    videoSliderStyles() {
      return {
        transform: `translateX(${this.videoSwiterData.x}px)`
      }
    },
    imgUrl(){
      let imgUrl = this.switerList.filter(v=>v.mediaType !== 'video');
      imgUrl = imgUrl.map(v=>v.mediaUrl);
      console.log(imgUrl)
      return imgUrl
    },
  },
  methods: {
    imgPreview(v){
      const imgUrl = this.imgUrl;
      const index = imgUrl.findIndex(item=>item===v);
      this.$viewerApi({
        images:this.imgUrl,
      }).view(index)
    },
    videoPreview(v) {
      this.videoShow = true;
      this.videoUrl = v;
    },
    leftClick() {
      const videoSwiterData = this.videoSwiterData;
      const switerList = this.switerList;
      if (!videoSwiterData.index) {
        this.videoSwiterData = {
          index: switerList.length - 1,
          x: -(switerList.length - 1) * 141
        }
      } else {
        this.videoSwiterData = {
          index: videoSwiterData.index - 1,
          x: videoSwiterData.x + 141
        }
      }
    },
    rightClick() {
      const videoSwiterData = this.videoSwiterData;
      const switerList = this.switerList;
      if (videoSwiterData.index === switerList.length - 1) {
        this.videoSwiterData = {
          index: 0,
          x: 0
        }
      } else {
        this.videoSwiterData = {
          index: videoSwiterData.index + 1,
          x: -((videoSwiterData.index + 1) * 141)
        }
      }
    },
  }
}
</script>


<style scoped lang="scss">
.switer_list {
  margin-top: 20px;
  width: 100%;
  height: 135px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  .switer_list_box {
    width: 99999999999px;
    display: flex;
    flex: 1;
    align-items: center;
    transition: all 0.5s;
  }

  .switer_inner {
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    border-radius: 6px;
    margin-right: 8px;
    max-width: 133px;
    min-width: 133px;
    width: 135px;
    height: 135px;
    position: relative;
  }

  .mini_show {
    display: block;
    width: 133px;
    height: 133px;
    max-width: 133px;
    max-height: 133px;
    object-fit: cover;
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
  }

  .video_mask {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999999999999;
    background: rgba(0, 0, 0, .3);
    cursor: pointer;
    width: 133px;
    height: 133px;
    text-align: center;
    line-height: 133px;
    color: #fff;
    font-size: 30px;
  }

  .switer_icon {
    //position: absolute;
    //top: 0;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    font-size: 23px;
    color: #9EB7FF;
    text-align: center;
    line-height: 134px;
    height: 134px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: -3px 0px 3px 0px rgba(56, 61, 94, 0.1);
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background: #C1D1FF;
    }
  }

  .switer_left {
    left: 0;
    margin-right: 18px;
  }

  .switer_rigth {
    right: 0;
    margin-left: 18px;
  }
}

.video_preview {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, .5);
  left: 0;
  top: 0;
  z-index: 99999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .video_close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    background-color: #606266;
    border-radius: 50%;
    cursor: pointer;
  }

  video {
    display: block;
    //margin: 60px auto;
    width: auto;
    height: 85%;
    object-fit: cover;
  }
}
.reshotClass{
  position: absolute;
  top: -2px;
  left: -1px;
  color: #FFFFFF;
  background-color: #FD677D;
  border-radius: 5px;
  font-size: 12px;
  padding: 1px 3px;
}

.empty{
  width: 100%;
  img{
    display: block;
    width: 96px;
    height: 50px;
    margin: 0 auto;
  }
  >p{
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
}

</style>
